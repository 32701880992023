import React from 'react'
import config from '../../config.json';
import { useState, useEffect } from 'react';
import axios from 'axios';
import CreateUserFormModal from './CreateUserFormModal';
import Pagination from '../../common_jsx/Pagination';
import DeleteUserConfirmationModal from './DeleteUserConfirmationModal';
import EditUserFormModal from './EditUserFormModal';
import Spinner from '../../common_jsx/Spinner'

function AdminUserScreen() {

    // For fetching Users
    const [users, setUsers] = useState([]);
    const [loadUser, setLoadUser] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [errorMessage, setErrorMessage] = useState('');

    const serviceUrl_list_users = `${config.serviceUrl}/user/list`

    const getUsers = async () => {

        try {
            setIsLoading(true);
            const response = await axios.get(serviceUrl_list_users, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                }
            });

            setUsers(response.data.data);
            setTotalRows(response.data.total_rows);

        } catch (error) {
            try {
                setErrorMessage(error.response.data.message);
            } catch (error) {
                setErrorMessage('Unable to Display Users.');
            }
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getUsers();
    }, []);

    //For Creating, Editing and Deleting User
    const [currentUser, setCurrentUser] = useState('');
    const [isCreatingUser, setIsCreatingUser] = useState(false);
    const [isEditingUser, setIsEditingUser] = useState(false);
    const [isDeletingUser, setIsDeletingUser] = useState(false);

    useEffect(() => {
        if (users.length > 0 && currentUser) {
            const updatedUser = users.find(user => user.id === currentUser.id);
            if (updatedUser) {
                setCurrentUser(updatedUser);
            }
        }
    }, [users]);

    const handleCreateClick = () => {
        setIsCreatingUser(true);
    }

    const handleEditClick = (user) => {
        setCurrentUser(user);
        setIsEditingUser(true);
    };

    const handleDeleteClick = (user) => {
        setCurrentUser(user);
        setIsDeletingUser(true);
    }

    const Buttons = [
        {
            label: "Edit",
            color: "secondary",
            onClick: (user) => handleEditClick(user)
        },
        {
            label: "Delete",
            color: "danger",
            onClick: (user) => handleDeleteClick(user)
        }
    ];

    return (
        <div className='chat-messages'>
            <div style={{ height: "54px" }} className="mb-3"></div>
            <div className='container mb-3'>
                <div className='text-left'>
                    <button onClick={handleCreateClick} className="btn btn-primary mb-3" type='button' >Create User</button>
                </div>

                {isCreatingUser &&
                    <CreateUserFormModal
                        isCreatingUser={isCreatingUser}
                        setIsCreatingUser={setIsCreatingUser}
                        // setCurrentUser={setCurrentUser}
                        setLoadUser={setLoadUser}
                        loadUser={loadUser}
                    />
                }

                {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

                {isLoading ? <Spinner /> :
                    users.length === 0 ?
                        errorMessage ? '' : <p className="text-center">No Users to Display</p>
                        :
                        <Pagination
                            displayData={users}
                            setDisplayData={setUsers}
                            pageNum={pageNum}
                            setPageNum={setPageNum}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                            totalRows={totalRows}
                            setTotalRows={setTotalRows}
                            source={"User"}
                            serviceUrl_pagination={serviceUrl_list_users}
                            excludedFields={['id']}
                            buttons={Buttons}
                            trigger={loadUser}
                            cellContent={[{ headerName: "roles", fieldName: "label" }]}
                        />}

                {isEditingUser &&
                    <EditUserFormModal
                        isEditingUser={isEditingUser}
                        setIsEditingUser={setIsEditingUser}
                        user={currentUser}
                        setLoadUser={setLoadUser}
                        loadUser={loadUser}
                    />
                }

                {isDeletingUser &&
                    <DeleteUserConfirmationModal
                        isDeletingUser={isDeletingUser}
                        setIsDeletingUser={setIsDeletingUser}
                        user={currentUser}
                        setLoadUser={setLoadUser}
                        loadUser={loadUser}
                    />
                }

            </div>
        </div>
    )
}

export default AdminUserScreen
