import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

function CommonModal({ show, setShow, modalContent, modalSize }) {

    const handleClose = () => setShow(false);

    return (
        <>
            <Modal show={show} onHide={handleClose} size={modalSize ? modalSize : 's'} >
                <Modal.Header closeButton>
                    <Modal.Title>{modalContent.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalContent.body}</Modal.Body>
                <div>
                    {modalContent.component}
                </div>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} disabled={modalContent.isLoading}>
                        Close
                    </Button>
                    {modalContent.action ? <Button variant={modalContent.buttonColour ? modalContent.buttonColour : 'primary'} onClick={modalContent.action} disabled={modalContent.isLoading || modalContent.disabledCondition ? modalContent.disabledCondition : false }>
                        {modalContent.isLoading && (
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="me-2"
                            />
                        )}
                        {modalContent.buttonName}
                    </Button> : ''
                    }
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default CommonModal;