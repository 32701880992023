import React, { useState } from 'react';
import config from '../../config.json';
import axios from 'axios';
import CommonModal from '../../common_jsx/CommonModal';
import RoleAssignmentDropDown from './RoleAssignmentDropDown';
import DisplayAndDeleteRole from './DisplayAndDeleteRole';
import EditUserForm from './EditUserForm';
import _ from 'lodash';

function EditUserFormModal(props) {

    const [name, setName] = useState(props.user.name);
    const [email, setEmail] = useState(props.user.email);
    const [group, setGroup] = useState(props.user.group);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [roles, setRoles] = useState(props.user.roles.map((role) => { return role }));
    const [rolesForAssignment, setRolesForAssignment] = useState([]);
    const [rolesForAssignmentDisplay, setRolesForAssignmentDisplay] = useState([]);

    const handleEditUser = async (e) => {

        try {
            e.preventDefault();
            setIsLoading(true);
            const serviceUrl_edit_user = `${config.serviceUrl}/user/update`

            const response = await axios.post(serviceUrl_edit_user, {
                user_id_to_edit: props.user.id,
                name: name !== props.user.name ? name : '',
                email: email !== props.user.email ? email : '',
                group: group,
                roles

            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                },
            });

            const data = response.data;
            props.setLoadUser(props.loadUser + 1);
            setSuccessMessage(data.message);
            setErrorMessage('');
            setName('');
            setEmail('');
            // Clear success message after 5 seconds
            setTimeout(() => {
                setSuccessMessage('');
            }, 5000);

        } catch (error) {
            try {
                setErrorMessage(error.response.data.message);
            } catch (error) {
                setErrorMessage('Register Unsuccessful');
            }
            setSuccessMessage('');
            // Clear error message after 5 seconds
            setTimeout(() => {
                setErrorMessage('');
            }, 5000);
            console.error('An error occurred during the edit request:', error.message);
        } finally {
            setIsLoading(false);
        }
    }


    const form = (
        <>
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            {successMessage && <div className="alert alert-success">{successMessage}</div>}
            <EditUserForm
                user={props.user}
                name={name}
                setName={setName}
                email={email}
                setEmail={setEmail}
                group={group}
                setGroup={setGroup}
            />

            <hr className="my-4" />

            <DisplayAndDeleteRole
                roles={roles}
                setRoles={setRoles}
                rolesForAssignment={rolesForAssignment}
                setRolesForAssignment={setRolesForAssignment}
                rolesForAssignmentDisplay={rolesForAssignmentDisplay}
                setRolesForAssignmentDisplay={setRolesForAssignmentDisplay}
            />

            <hr className="my-4" />

            <RoleAssignmentDropDown
                roles={roles}
                setRoles={setRoles}
                rolesForAssignment={rolesForAssignment}
                setRolesForAssignment={setRolesForAssignment}
                rolesForAssignmentDisplay={rolesForAssignmentDisplay}
                setRolesForAssignmentDisplay={setRolesForAssignmentDisplay}
            />
        </>
    )

    const editUserContent = {
        heading: "Edit User",
        body: form,
        action: handleEditUser,
        isLoading: isLoading,
        disabledCondition: name === props.user.name && email === props.user.email && group === props.user.group && _.isEqual(roles, props.user.roles),
        buttonName: "Save"
    }

    return (
        <CommonModal
            show={props.isEditingUser}
            setShow={props.setIsEditingUser}
            modalContent={editUserContent}
        />
    )

}

export default EditUserFormModal
