import React from 'react';
import DataSourceCard from './DataSourceCard';

function DataSources({ isLoading, savedsources, setConductReload }) {

    if (isLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100px' }}>
                <div className="spinner-grow text-primary" role="status"></div>
                <span className="ms-2 fw-semibold" style={{ fontSize: '20px' }}>Loading Active Data Sources...</span>
            </div>
        );
    }

    if (savedsources.length === 0) {
        return (
            <div className="d-flex justify-content-center mt-3">
                <div className="alert alert-info" role="alert">
                    No Active Data Sources, Click Above To Add A Configuration
                </div>
            </div>
        );
    }

    let rows = [];
    for (let i = 0; i < savedsources.length; i += 3) {
        rows.push(
            <div key={i} className="row mx-2">
                {savedsources.slice(i, i + 3).map((savedsource, index) => (
                    <DataSourceCard key={index} savedsource={savedsource} setConductReload={setConductReload} />
                ))}
            </div>
        );
    }
    return rows;
}

export default DataSources;
