import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import 'bootstrap/dist/js/bootstrap.min.js'
import "./elastiq.css"
import MainContent from './MainContent';
import axios from 'axios';
import config from './config.json';

import React, { useState, useEffect, useMemo } from 'react';

import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

//Auth
import LoginForm from './auth/login_form'
import Spinner from './common_jsx/Spinner';

function App() {

  const [user, setUser] = useState({ id: '', name: ''});
  //Auth
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  const serviceUrl_get_user = `${config.serviceUrl}/user/get`;
  const curr_token = localStorage.getItem('token');
  const config_home = useMemo(() => {
    return {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': curr_token
      }
    };
  }, [curr_token]);

  // Check if the user is authenticated
  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(serviceUrl_get_user, config_home);
        if (response.status === 200) {
          setAuthenticated(true);
          setUser(response.data.user);

        }
      } catch (error) {
        handleLogout();
      } finally {
        setLoading(false);
      }
    })();
  }, [config_home, serviceUrl_get_user]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setAuthenticated(false);
  };

  return (
    <>
      {loading ? <Spinner /> :
        <Router>
          <Routes>
            <Route
              path="/login"
              element={authenticated ? <Navigate to="/" /> :
                <LoginForm
                  setAuthenticated={setAuthenticated}
                  setUser={setUser}
                />
              }
            />
            <Route
              path="/"
              element={authenticated ? (
                <MainContent
                  user={user}
                  handleLogout={handleLogout}
                  configHome={config_home}
                  authenticated={authenticated}
                />
              ) : (
                <Navigate to="/login" />
              )}
            />
          </Routes>
        </Router>
      }
    </>

  );
}

export default App;
