import React from 'react';
import axios from 'axios';
import config from '../../config.json';
import { useState } from 'react';
import CommonModal from '../../common_jsx/CommonModal';

function DeleteUserConfirmationModal(props) {

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleDeleteUser = async () => {
        try {
            setIsLoading(true);
            const serviceUrl_delete_user = `${config.serviceUrl}/user/delete`;

            const response = await axios.post(
                serviceUrl_delete_user,
                {
                    user_id_to_delete: props.user.id,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: localStorage.getItem('token'),
                    },
                }
            );
            const data = response.data;
            setSuccessMessage(data.message);
            setErrorMessage('');
            props.setLoadUser(props.loadUser + 1);
            setTimeout(() => {
                setSuccessMessage('');
            }, 5000);

        } catch (error) {
            try {
                setErrorMessage(error.response.data.message);
            } catch (error) {
                setErrorMessage('Deletion Unsuccessful');
            }
            setSuccessMessage('');
            // Clear error message after 5 seconds
            setTimeout(() => {
                setErrorMessage('');
            }, 5000);
        } finally {
            setIsLoading(false);
            setTimeout(() => {
                props.setIsDeletingUser(false);
            }, 5000);
        }
    };

    const deleteBody = (
        <>
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            {successMessage && <div className="alert alert-success">{successMessage}</div>}
            <p>Are you sure you want to Delete User</p>
        </>
    )

    const deleteUserContent = {
        heading: "Delete User",
        body: deleteBody,
        action: handleDeleteUser,
        buttonName: "Delete",
        buttonColour: "danger",
        isLoading: isLoading
    }

    return (
        <CommonModal
            show={props.isDeletingUser}
            setShow={props.setIsDeletingUser}
            modalContent={deleteUserContent}
        />
    );
}

export default DeleteUserConfirmationModal;
