import React, { useState } from 'react';
import axios from 'axios';
import config from '../../config.json';
import CommonModal from '../../common_jsx/CommonModal';
import CreateUserForm from './CreateUserForm';
import DisplayAndDeleteRole from './DisplayAndDeleteRole';
import RoleAssignmentDropDown from './RoleAssignmentDropDown';

function CreateUserFormModal(props) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [group, setGroup] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [roles, setRoles] = useState([]);
    const [rolesForAssignment, setRolesForAssignment] = useState([]);
    const [rolesForAssignmentDisplay, setRolesForAssignmentDisplay] = useState([]);


    const handleUserCreation = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            const response = await axios.post(
                `${config.serviceUrl}/user/create`,
                {
                    name,
                    email,
                    password,
                    roles,
                    group
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': localStorage.getItem('token')
                    },
                }
            );

            const data = response.data;
            setSuccessMessage(data.message);
            setErrorMessage('');
            setName('');
            setEmail('');
            setPassword('');
            props.setLoadUser(props.loadUser + 1);
            // Clear success message after 5 seconds
            setTimeout(() => {
                setSuccessMessage('');
            }, 5000);
        } catch (error) {
            try {
                setErrorMessage(error.response.data.message);
            } catch (error) {
                setErrorMessage('Register Unsuccessful');
            }
            setSuccessMessage('');
            // Clear error message after 5 seconds
            setTimeout(() => {
                setErrorMessage('');
            }, 5000);
        } finally {
            setIsLoading(false);
        }
    };

    const form = (
        <>
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            {successMessage && <div className="alert alert-success">{successMessage}</div>}

            <CreateUserForm
                name={name}
                setName={setName}
                email={email}
                setEmail={setEmail}
                password={password}
                setPassword={setPassword}
                group={group}
                setGroup={setGroup}
            />

            <hr className="my-4" />

            <DisplayAndDeleteRole
                roles={roles}
                setRoles={setRoles}
                rolesForAssignment={rolesForAssignment}
                setRolesForAssignment={setRolesForAssignment}
                rolesForAssignmentDisplay={rolesForAssignmentDisplay}
                setRolesForAssignmentDisplay={setRolesForAssignmentDisplay}
            />

            <hr className="my-4" />

            <RoleAssignmentDropDown
                roles={roles}
                setRoles={setRoles}
                rolesForAssignment={rolesForAssignment}
                setRolesForAssignment={setRolesForAssignment}
                rolesForAssignmentDisplay={rolesForAssignmentDisplay}
                setRolesForAssignmentDisplay={setRolesForAssignmentDisplay}
            />
        </>
    );

    const createUserContent = {
        heading: "Create User",
        body: form,
        action: handleUserCreation,
        isLoading: isLoading,
        disabledCondition: isLoading || !name || !email || roles.length === 0,
        buttonName: "Create"
    }

    return (
        <CommonModal
            show={props.isCreatingUser}
            setShow={props.setIsCreatingUser}
            modalContent={createUserContent}
        />
    )
}

export default CreateUserFormModal;
