import React from 'react';

function DisplayAndDeleteRoleContent({ role, roles, setRoles, rolesForAssignment, rolesForAssignmentDisplay, setRolesForAssignmentDisplay }) {

    const handleDeleteRole = () => {

        if (rolesForAssignment.some(r => r.name === role.name)) {
            const updatedRoles = roles.filter(r => r.name !== role.name);
            setRoles(updatedRoles);
            setRolesForAssignmentDisplay([...rolesForAssignmentDisplay, role]);
        }
    }

    return (
        <li className="list-group-item d-flex justify-content-between align-items-center">
            {role.label}
            <div className="btn-group">
                <button
                    type="button"
                    className="btn btn-sm btn-outline-danger delete-role"
                    onClick={handleDeleteRole}
                >
                    Delete
                </button>
            </div>
        </li>
    )
}

export default DisplayAndDeleteRoleContent
