import React, { useState } from 'react';
import axios from 'axios';
import CommonModal from '../../common_jsx/CommonModal'
import config from '../../config.json';

function DataSourceUpdateModal({ savedsource, showUpdateModal, setShowUpdateModal, setConductReload }) {

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('success');
    const [isLoading, setIsLoading] = useState(false);
    // Logic for creating object for POST request and submitting to the add filfillment source endpoint
    const HandleSubmit = async (e, savedsource) => {
        e.preventDefault();
        // Start the loading animation
        setIsLoading(true);
        // Close the Previous Alert if applicable
        setShowAlert(false);
        // Next few lines of code is getting the form data ready for submission to the add-fulfillment sources request
        const form = e.target;
        const formData = new FormData(form);
        const formObject = Object.fromEntries(formData.entries());
        // Separate the 'name' field
        const { name, ...configuration } = formObject;
        // Construct the desired object structure
        const formattedData = {
            configuration: configuration,
            name: name,
            source: {
                id: savedsource.id,
                name: savedsource.source.name,
            }
        };
        // Extracting the service_account_key as a string. It needs to be changed to a JSON object
        const serviceAccountKeyString = formattedData.configuration.service_account_key;
        try {
            // TODO: Check if the master configuration has data type as "json" for any field, then prase all those fields. We should not specifically look for "serviceAccountKey".
            // Attempting to parse the string to an object
            const serviceAccountKeyObject = JSON.parse(serviceAccountKeyString);
            // If parsing is successful, assigning the parsed object back to the original data structure
            formattedData.configuration.service_account_key = serviceAccountKeyObject;
        } catch (error) {
            // If parsing fails, log the error (or handle it as needed)
            console.error("Parsing error: ", error);
        }
        const config_home = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            }
        };
        // Put request for updating database
        try {
            const response = await axios.put(`${config.serviceUrl}/fulfillment-source/update`, formattedData, config_home);
            // Set success alert
            setAlertMessage(response.data.message);
            setAlertType('success');
            setShowAlert(true);
            setConductReload(prevState => !prevState);
        } catch (error) {
            console.error('There was an error!', error);
            // Set error alert
            setAlertMessage(error.message);
            setAlertType('danger');
            setShowAlert(true);
        }
        setIsLoading(false);
    };

    const updatebody = (
        <>
            {showAlert && (
                <div className={`alert alert-${alertType}`} role="alert">
                    {alertMessage}
                </div>)}
            <form onSubmit={(e) => HandleSubmit(e, savedsource)}>
                <div className="mb-3">
                    <label className="form-label">Name</label>
                    <input type="text" name="name" className="form-control" defaultValue={savedsource.name} required />
                </div>
                {Object.entries(savedsource.configuration).map(([key, value], index) => {
                    // Check if the value is an object and convert it to a string if necessary
                    const stringValue = (typeof value === 'object' && value !== null) ? JSON.stringify(value) : value;
                    return (
                        <div className="mb-3" key={index}>
                            <label className="form-label">{key}</label>
                            <input type="text" name={key} className="form-control" defaultValue={stringValue} required />
                        </div>
                    );
                })}
                <button type="submit" className="btn btn-primary" disabled={isLoading}>
                    {isLoading &&
                        <div className="spinner-border spinner-border-sm" role="status">
                            <span className="sr-only"></span>
                        </div>}
                    {isLoading ? ' Working...' : 'Update'}
                </button>
            </form>
        </>
    )

    const updateDataSourceContent = {
        heading: "Update Data Source",
        body: updatebody,
        isLoading: isLoading
    }

    return (
        <CommonModal
            show={showUpdateModal}
            setShow={setShowUpdateModal}
            modalContent={updateDataSourceContent}
        />
    )
}

export default DataSourceUpdateModal
