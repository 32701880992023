import React from 'react'

function CreateUserForm({ name, setName, email, setEmail, password, setPassword, group, setGroup }) {
    return (
        <form>
            <div className="mb-3">
                <label htmlFor="name" className="form-label">
                    Name
                </label>
                <input
                    id="name"
                    name="name"
                    type="text"
                    className="form-control"
                    required={true}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </div>
            <div className="mb-3">
                <label htmlFor="email" className="form-label">
                    Email
                </label>
                <input
                    id="email"
                    name="email"
                    type="email"
                    className="form-control"
                    required={true}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>
            <div className="mb-3">
                <label htmlFor="password" className="form-label">
                    Password
                </label>
                <input
                    id="password"
                    name="password"
                    type="password"
                    className="form-control"
                    required={true}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </div>
            <div className="mb-3">
                <label htmlFor="group" className="form-label">
                    Group
                </label>
                <input
                    id="group"
                    name="group"
                    type="group"
                    className="form-control"
                    value={group}
                    onChange={(e) => setGroup(e.target.value)}
                />
            </div>
        </form>
    )
}

export default CreateUserForm
