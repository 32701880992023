import React from 'react';

function EditUserForm({ user, name, setName, email, setEmail, group, setGroup }) {

    return (
        <div>
            <label className="form-label">Edit Profile</label>
            <form>
                <div className="mb-3">
                    <label
                        htmlFor="userName"
                        className="form-label"
                    >
                        Name
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="userName"
                        name='name'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder={user.name}
                    />
                </div>
                <div className="mb-3">
                    <label
                        htmlFor="userEmail"
                        className="form-label"
                    >
                        Email
                    </label>
                    <input
                        type="email"
                        className="form-control"
                        id="userEmail"
                        name='email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder={user.email}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="group" className="form-label">
                        Group
                    </label>
                    <input
                        id="group"
                        name="group"
                        type="group"
                        className="form-control"
                        value={group}
                        onChange={(e) => setGroup(e.target.value)}
                    />
                </div>
            </form>
        </div>
    )
}

export default EditUserForm
