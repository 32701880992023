import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../config.json';

function RoleAssignmentDropDown({ roles, setRoles, setRolesForAssignment , rolesForAssignmentDisplay, setRolesForAssignmentDisplay}) {

    const [assignedRole, setAssignedRole] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const getRolesForAssignment = async () => {
        try {
            setIsLoading(true);
            const response = await axios.get(`${config.serviceUrl}/role/list`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                }
            });
            setRolesForAssignment(response.data.data);
            setRolesForAssignmentDisplay(response.data.data.filter(role => !roles.some(r => r.name === role.name)));

        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getRolesForAssignment();
    }, [])

    const handleAddRole = () => {
        if (assignedRole && !roles.some(r => r.name === assignedRole.name)) {
            setRoles([...roles, assignedRole]);
            setRolesForAssignmentDisplay(rolesForAssignmentDisplay.filter(r => r.name !== assignedRole.name));
            setAssignedRole(null);
        }
    }

    return (
        <div className="mt-3">
            <label className="form-label">Assign Role</label>
            <div className="input-group mb-3">
                {
                    isLoading ?
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="spinner-grow text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                        :
                        <div className="dropdown">
                            <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {assignedRole ? assignedRole.label : "Select Role"}
                            </button>
                            <ul className="dropdown-menu">
                                {rolesForAssignmentDisplay.length > 0 ? (
                                    rolesForAssignmentDisplay.map((role, index) => (
                                        <li
                                            key={index}
                                            role="button"
                                            onClick={() => { setAssignedRole(role) }}
                                        >
                                            <button className="dropdown-item">{role.label}</button>
                                        </li>
                                    ))
                                ) : (
                                    <li className="dropdown-item">No roles available</li>
                                )}
                            </ul>
                        </div>
                }
                <button
                    className="btn btn-primary ms-3"
                    disabled={isLoading || !assignedRole}
                    onClick={handleAddRole}
                >
                    Add
                </button>
            </div>
        </div>
    )
}

export default RoleAssignmentDropDown;
