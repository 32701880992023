import React, { useState } from 'react'

import DataSourceDeleteModal from './DataSourceDeleteModal';
import DataSourceUpdateModal from './DataSourceUpdateModal';

function DataSourceCard({ savedsource, setConductReload }) {

    const imageSrc = `${savedsource.source.name}.png`;

    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleUpdateClick = () => {
        setShowUpdateModal(true);
    }

    const handleDeleteClick = () => {
        setShowDeleteModal(true);
    }

    return (
        <>
            <div key={savedsource.id} className="col-md-4 col-12 mb-4">
                <div className="card shadow-sm border-0">
                    <div className="card-body">
                        <div className="row align-items-center">
                            <div className="col">
                                <h5 className="card-title mb-2 font-weight-bold text-left">{savedsource.name}</h5>
                            </div>
                            <div className="col-auto">
                                <img src={imageSrc} height="40" className="float-end rounded" alt={`${savedsource.name} Logo`} />
                            </div>
                        </div>
                        {/* <div className="mt-3">
                        <span className="badge bg-success">Active</span>
                    </div> */}
                        {/* Buttons for Update and Delete */}
                        <div className="d-flex justify-content-end mt-3">
                            <button type="button" className="btn btn-outline-primary me-2" onClick={handleUpdateClick}>Update</button>
                            <button type="button" className="btn btn-danger" onClick={handleDeleteClick}>Delete</button>
                        </div>
                    </div>
                </div>
            </div>

            {showUpdateModal &&
                <DataSourceUpdateModal
                    savedsource={savedsource}
                    showUpdateModal={showUpdateModal}
                    setShowUpdateModal={setShowUpdateModal}
                    setConductReload={setConductReload}
                />
            }

            {showDeleteModal &&
                <DataSourceDeleteModal
                    savedsource={savedsource}
                    showDeleteModal={showDeleteModal}
                    setShowDeleteModal={setShowDeleteModal}
                    setConductReload={setConductReload}
                />
            }

        </>
    );
}

export default DataSourceCard
