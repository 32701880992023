import React from 'react';
import DisplayAndDeleteRoleContent from './DisplayAndDeleteRoleContent';

function DisplayAndDeleteRole({ roles, setRoles, rolesForAssignment, setRolesForAssignment, rolesForAssignmentDisplay, setRolesForAssignmentDisplay }) {

    return (
        <div className='mt-3'>
            <label className="form-label">Current Roles</label>
            <ul className="list-group roles-list">
                {roles.map((role) => (
                    <DisplayAndDeleteRoleContent
                        key={role}
                        role={role}
                        roles={roles}
                        setRoles={setRoles}
                        rolesForAssignment={rolesForAssignment}
                        setRolesForAssignment={setRolesForAssignment}
                        rolesForAssignmentDisplay={rolesForAssignmentDisplay}
                        setRolesForAssignmentDisplay={setRolesForAssignmentDisplay}
                    />
                ))}
            </ul>
        </div>
    );
}

export default DisplayAndDeleteRole;
