import React, { useState } from 'react';
import axios from 'axios';
import CommonModal from '../../common_jsx/CommonModal'
import config from '../../config.json';

function AddDataSource({ source, showAddModal, setShowAddModal, setConductReload }) {

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('success');
    const [isLoading, setIsLoading] = useState(false);

    const HandleSubmit = async (e, source) => {
        e.preventDefault();
        // Start the loading animation
        setIsLoading(true);
        // Close the Previous Alert if applicable
        setShowAlert(false);
        // Next few lines of code is getting the form data ready for submission to the add-fulfillment sources request
        const form = e.target;
        const formData = new FormData(form);
        const formObject = Object.fromEntries(formData.entries());
        // Separate the 'name' field
        const { name, ...configuration } = formObject;
        // Construct the desired object structure
        const formattedData = {
            configuration: configuration,
            name: name,
            source: {
                id: source.id,
                name: source.name,
            }
        };
        // Extracting the service_account_key as a string. It needs to be changed to a JSON object
        const serviceAccountKeyString = formattedData.configuration.service_account_key;
        try {
            // TODO: Check if the master configuration has data type as "json" for any field, then prase all those fields. We should not specifically look for "serviceAccountKey".
            // Attempting to parse the string to an object
            const serviceAccountKeyObject = JSON.parse(serviceAccountKeyString);
            // If parsing is successful, assigning the parsed object back to the original data structure
            formattedData.configuration.service_account_key = serviceAccountKeyObject;
        } catch (error) {
            // If parsing fails, log the error 
            console.error("Parsing error: ", error);
        }
        const config_home = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            }
        };
        // Post Request for adding a database
        try {
            const response = await axios.post(`${config.serviceUrl}/fulfillment-source/create`, formattedData, config_home);
            // Reset the form fields here after a successful POST request
            form.reset();
            // Set success alert
            setAlertMessage(response.data.message);
            setAlertType('success');
            setShowAlert(true);
            setConductReload(prevState => !prevState);
        } catch (error) {
            console.error('There was an error!', error);
            // Set error alert
            setAlertMessage(error.response.data.message);
            setAlertType('danger');
            setShowAlert(true);
        }
        setIsLoading(false);
    };

    const addBody = (
        <>
            {showAlert && (
                <div className={`alert alert-${alertType}`} role="alert">
                    {alertMessage}
                </div>
            )}
            <form onSubmit={(e) => HandleSubmit(e, source)}>
                <div className="mb-3">
                    <label className="form-label">Name</label>
                    <input type="text" name="name" className="form-control" required />
                </div>
                {Object.entries(source.configuration).map(([key], index) => {
                    return (
                        <div className="mb-3" key={index}>
                            <label className="form-label">{key}</label>
                            <input type="text" name={key} className="form-control" required />
                        </div>
                    );
                })}
                <button type="submit" className="btn btn-primary" disabled={isLoading}>
                    {isLoading &&
                        <div className="spinner-border spinner-border-sm" role="status">
                            <span className="sr-only"></span>
                        </div>}
                    {isLoading ? ' Working...' : 'Add Configuration'}
                </button>
            </form>
        </>
    );

    const addDataSourceContent = {
        heading: "Add Data Source",
        body: addBody,
        isLoading: isLoading
    }

    return (
        <CommonModal
            show={showAddModal}
            setShow={setShowAddModal}
            modalContent={addDataSourceContent}
        />
    )
}

export default AddDataSource
