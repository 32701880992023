import React, { useState, useEffect } from "react";
import axios from "axios";

const Pagination = ({
    displayData,
    setDisplayData,
    pageNum,
    setPageNum,
    pageSize,
    setPageSize,
    totalRows,
    setTotalRows,
    source,
    serviceUrl_pagination,
    excludedFields,
    buttons,
    columnButtons,
    trigger,
    cellContent,
}) => {
    let page_num = pageNum;
    let page_size = pageSize;

    const headers = Object.keys(displayData[0]).filter(
        (header) => !excludedFields.includes(header)
    );

    if (buttons.length > 0) {
        headers.push("Actions");
    }

    const handleCellContent = (d, h, displayField) => {
        if (Array.isArray(d[h])) {
            return d[h].map(data => data[displayField]).join(", ");
        }  else {
            return d[h];
        }
    };

    const [isLoading, setIsLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(
        Math.ceil(totalRows / page_size)
    );

    // Create an array of page numbers
    let start_page = Math.max(1, pageNum - 2);
    let end_page = Math.min(totalPages, start_page + 4);
    if (end_page - start_page < 4) {
        start_page = Math.max(1, end_page - 4);
    }
    let pages = [];
    for (let i = start_page; i <= end_page; i++) {
        pages.push(i);
    }

    const handlePageSizeChange = (event) => {
        setPageSize(event.target.value);
        page_size = event.target.value;
        setTotalPages(Math.ceil(totalRows / event.target.value));
        handlePageNumChange(1);
    };

    const handlePageNumChange = (p) => {
        setPageNum(p);
        page_num = p;
        load_page();
    };

    const load_page = async () => {
        try {
            setIsLoading(true);
            const params = {
                source: source,
                page_num: page_num,
                page_size: page_size,
            };
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem("token"),
                },
            };
            const response = await axios.get(serviceUrl_pagination, {
                params,
                ...config,
            });
            if (response.data) {
                setDisplayData(response.data.data);
                if (response.data.total_rows) {
                    setTotalRows(response.data.total_rows);
                }
            } else {
                console.log("Response does not contain botMessage");
                // Handle the case where the response structure is unexpected
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };
    const calculateRange = (pageNum, pageSize, totalRows) => {
        const startRecord = (pageNum - 1) * pageSize + 1;
        const endRecord = Math.min(pageNum * pageSize, totalRows);
        return `${startRecord} - ${endRecord} of ${totalRows} records`;
    };

    useEffect(() => {
        if (trigger !== 0) {
            load_page();
        }
    }, [trigger]);

    const uniqueId = `inputGroupSelect-${Math.random()
        .toString(36)
        .substr(2, 9)}`;

    return (
        <div className="card">
            <div className="card-body">
                <div className="row mb-2">
                    <div className="col-md-6 text-start">
                        {isLoading && (
                            <span
                                className="spinner-border spinner-border-sm me-2"
                                role="status"
                                aria-hidden="true"
                            ></span>
                        )}
                        Showing {calculateRange(pageNum, pageSize, totalRows)}
                    </div>
                    <div className="col-md-6 text-end">
                        <div className="dropdown">
                            <button
                                className="btn btn-light border dropdown-toggle"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <i className="bi bi-gear"></i>
                            </button>
                            <div className="dropdown-menu dropdown-menu-end">
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item">
                                        <div className="input-group">
                                            <label className="input-group-text" htmlFor={uniqueId}>
                                                Number of rows to display
                                            </label>
                                            <select
                                                className="form-select"
                                                id={uniqueId}
                                                value={pageSize}
                                                disabled={isLoading}
                                                onChange={(event) => handlePageSizeChange(event)}
                                            >
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                            </select>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overflow-x-auto">
                    <table className="table table-bordered table-sm">
                        <thead>
                            <tr>
                                {headers.map((h, i) => (
                                    <th key={i} scope="col">
                                        {typeof h === 'object' ? JSON.stringify(h) : h[0].toUpperCase() + h.slice(1)}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {displayData.map((d, i) => (
                                <tr key={i}>
                                    {headers.map((h, j) => (
                                        <td key={j} className="px-2 py-1">
                                            {h === "Actions" ? (
                                                buttons.map((button, index) => (
                                                    <button
                                                        key={index}
                                                        className={`btn btn-${button.color} btn-sm m-2`}
                                                        onClick={() => button.onClick(d)}
                                                        disabled={button.disable && button.disable(d)}
                                                    >
                                                        {button.label}
                                                    </button>
                                                ))
                                            ) : (
                                                columnButtons && columnButtons.some(button => button.name === h) ? (
                                                    columnButtons.map((button) =>
                                                        button.name === h ? (
                                                            <button
                                                                className={`btn ${button.color ? button.color(d[h]) : "btn-secondary"} btn-sm m-2`}
                                                                onClick={() => button.onClick ? button.onClick(d) : ""}
                                                            >
                                                                {d[h]}
                                                            </button>
                                                        ) : null
                                                    )
                                                ) : (
                                                    cellContent && cellContent.some(c => c.headerName === h) ? (
                                                        cellContent.map((c) =>
                                                            c.headerName === h ? handleCellContent(d, h, c.fieldName) : null
                                                        )
                                                    ) : (
                                                        typeof d[h] === 'object' ? JSON.stringify(d[h]) : d[h]
                                                    )
                                                )
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <nav>
                    <ul className="pagination mb-0 justify-content-end">
                        <li
                            className={`page-item ${(pageNum === 1 || isLoading) && "disabled"
                                }`}
                        >
                            <button
                                className="page-link"
                                onClick={() => {
                                    handlePageNumChange(1);
                                }}
                            >
                                First
                            </button>
                        </li>
                        <li
                            className={`page-item ${(pageNum === 1 || isLoading) && "disabled"
                                }`}
                        >
                            <button
                                className="page-link"
                                onClick={() => {
                                    handlePageNumChange(pageNum - 1);
                                }}
                            >
                                Previous
                            </button>
                        </li>
                        {pages.map((p, i) => (
                            <li
                                key={i}
                                className={`page-item ${p === pageNum && "active"} ${isLoading && "disabled"
                                    }`}
                            >
                                <button
                                    className="page-link"
                                    onClick={() => {
                                        handlePageNumChange(p);
                                    }}
                                >
                                    {p}
                                </button>
                            </li>
                        ))}
                        <li
                            className={`page-item ${(pageNum === totalPages || isLoading) && "disabled"
                                }`}
                        >
                            <button
                                className="page-link"
                                onClick={() => {
                                    handlePageNumChange(pageNum + 1);
                                }}
                            >
                                Next
                            </button>
                        </li>
                        <li
                            className={`page-item ${(pageNum === totalPages || isLoading) && "disabled"
                                }`}
                        >
                            <button
                                className="page-link"
                                onClick={() => {
                                    handlePageNumChange(totalPages);
                                }}
                            >
                                Last
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default Pagination;