import React from 'react';
import { useRef, useEffect, useState } from 'react';
import ChatMessage from './ChatMessage';
import MessageInput from './MessageInput';
import ChatTypingIndicator from './ChatTypingIndicator';
import axios from 'axios';
import 'bootstrap/dist/js/bootstrap.min.js';
import config from '../config.json';

function ChatScreen(props) {

    const [inputText, setInputText] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const [quotedMessage, setQuotedMessage] = useState(null);
    const [currentParentId, setCurrentParentId] = useState(null);
    const inputTextArea = useRef(null);
    const serviceUrl = config.serviceUrl;
    const token = localStorage.getItem('token');
    const config_home = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        }
    };

    useEffect(() => {
        // Scroll to the end when liveChat or isTyping changes
        scrollToBottom();
    }, [props.liveChat, isTyping]);

    const scrollToBottom = () => {
        const chatMessages = document.querySelector('.chat-messages');
        chatMessages.scrollTop = chatMessages.scrollHeight;
    };

    const resizeInput = () => {
        const elem = inputTextArea.current;
        if (elem) {
            elem.style.height = '58px';
            elem.style.height = `${elem.scrollHeight}px`;
        }
    };
    // Handler for the input change
    const handleInputChange = (event) => {
        setInputText(event.target.value);
        resizeInput();
    };

    const updateNewSessionName = async (chatSessionId) => {
        try {
            const response = await axios.post(serviceUrl + "/session/set-name",
                { chatSessionId: chatSessionId },
                config_home
            );

            props.setChatSessions((prevSessions) => {
                const updatedSessions = [...prevSessions];
                const lastSessionIndex = updatedSessions.length - 1;
                updatedSessions[lastSessionIndex].summary = response.data.session_name;
                return updatedSessions;
            });
        } catch (error) {
            handleSendError(error);
        }
    }

    const handleSend = async () => {
        if (!inputText) {
            return;
        }
        const tempChatId = props.user.id + Date.now().toString();
        const requestBody = {
            id: tempChatId,
            message: inputText,
            chat_session_id: props.activeChatSession,
            parent_message_id: currentParentId,
            author_id: props.user.id
        };
        props.setLiveChat(prevMessage => [...prevMessage, requestBody]);

        setIsTyping(true);
        setInputText('');
        setQuotedMessage(null);
        setCurrentParentId(null);
        resizeInput();
        let chatSessionId;
        try {
            const response = await axios.post(serviceUrl, requestBody, config_home);

            props.setLiveChat((prevMessages) => {
                const updatedMessages = [...prevMessages];
                const lastMessageIndex = updatedMessages.length - 1;
                updatedMessages[lastMessageIndex].id = response.data.user_message_id;
                return updatedMessages;
            });
            chatSessionId = response.data.chat_session_id;
            props.setLiveChat(prevMessage => [...prevMessage, response.data.bot_message]);
            if (!props.activeChatSession) {
                const newChatSession = { id: chatSessionId, summary: "New Session" };
                props.setChatSessions(prevSessions => [...prevSessions, newChatSession]);
                props.setActiveChatSession(chatSessionId);
            }
        } catch (error) {
            handleSendError(error);
        } finally {
            setIsTyping(false);
            if (!props.activeChatSession) {
                updateNewSessionName(chatSessionId);
            }
        }
    };

    function handleSendError(error) {
        try {
            if (error.response && error.response.status === 401) {
                props.handleLogout();
                window.alert("Unauthorized Access!");
            } else {
                console.error(error);
                window.alert("Something went wrong! " + error.message);
            }
        } catch (error) {
            console.error(error);
            window.alert("Something went wrong! " + error.message);
        }
    }

    const handleInputKeyPress = (event) => {
        // Check if the 'Enter' key was pressed
        if (event.key === 'Enter' && !event.shiftKey) {
            // Prevent the default action to stop it from submitting a form if there's one
            event.preventDefault();
            handleSend();
        }
        if (event.key === 'ArrowUp' && inputText === '') {
            // If up key, get previous message
            event.preventDefault();
            for (let i = props.liveChat.length - 1; i >= 0; i--) {
                if (props.liveChat[i].author_id === props.user.id) {
                    // return the object if the id matches
                    setInputText(props.liveChat[i].message);
                    break;
                }
            }
        }
    }

    const set_signed_cookie = async () => {
        try {
            await axios.get(serviceUrl + "/set-signed-cookie", {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                },
                withCredentials: true
            });
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        set_signed_cookie();

        // Timer to refresh the cookie every 15 minutes 
        const cookieRefreshTimer = setInterval(() => {
            set_signed_cookie();
        }, 780000);

        // Timer will be cleaned if component unmounts.
        return () => clearInterval(cookieRefreshTimer);
    }, []);

    return (
        <div className="chat-messages d-flex flex-column-reverse" onScroll={props.handleScroll}>
            <div className="position-relative">
                <div className="chat-message bg-white" style={{ height: "56px" }}></div>
                {props.liveChat.map((msg) => {
                    return (
                        <ChatMessage
                            key={msg.id}
                            id={msg.id}
                            author_id={msg.author_id === null ? 0 : props.user.id}
                            author={msg.author_id === null ? 'Elastiq' : props.user.name}
                            avatar={msg.author_id === null ? '/elastiq_logo.png' : '/user.png'}
                            message={msg.message}
                            source={msg.source}
                            total_rows={msg.total_rows}
                            datatype={msg.type}
                            parentMessage={msg.parent_message_id ? props.liveChat.find((message) => message.id === msg.parent_message_id) : null}
                            resizeInput={resizeInput}
                            setQuotedMessage={setQuotedMessage}
                            setCurrentParentId={setCurrentParentId}
                            currentParentId={currentParentId}
                        />
                    );
                })}

                {isTyping && <ChatTypingIndicator />}
                <div className="chat-message p-4 border-top bg-white" style={{ height: "100px" }}></div>
            </div>
            <div className="position-fixed fixed-bottom row justify-content-end">
                <div className={`col-12 col-md-3 col-lg-2 ${props.isSideBar ? '' : 'hidden-div'}`}>
                </div>
                <div className={`py-3 px-4 col-12 ${props.isSideBar && 'col-md-9 col-lg-10'} transition-width row`} >
                    <div className="col-lg-2"></div>
                    <div className="col-lg-8">
                        <MessageInput
                            value={inputText}
                            onChange={handleInputChange}
                            onKeyDown={handleInputKeyPress}
                            onClick={handleSend}
                            quotedMessage={quotedMessage}
                            setQuotedMessage={setQuotedMessage}
                            setCurrentParentId={setCurrentParentId}
                            isTyping={isTyping}
                        />
                    </div>
                    <div className="col-lg-2"></div>
                </div>
            </div>
        </div>

    );
};
export default ChatScreen;
