import React, { useState } from 'react'
import AddDataSource from './AddDataSource'

function DataSourceDropDown({ sources, setConductReload }) {

    const [showAddModal, setShowAddModal] = useState(false);
    const [source, setSource] = useState(null);

    const handleAddClick = (source) => { 
        setShowAddModal(true);
        setSource(source) 
    }

    return (
        <>
            <div className="container pb-3">
                <div className="row justify-content-center">
                    <div className="col-auto">
                        <div className="btn-group dropdown-center">
                            <button className="btn btn-secondary btn-lg dropdown-toggle" type="button" data-bs-toggle="dropdown">
                                Connect Your Data Source
                            </button>
                            <ul className="dropdown-menu">
                                {sources.map((source) => (
                                    <li key={source.id} className="dropdown-item" role='button' onClick={() => {handleAddClick(source)}}>
                                        <button type="button" className="btn text-button" >
                                            <img src={`${source.name}.png`} height="25px" className="d-inline-block align-middle image-spacing me-2" alt={source.label} />
                                            {source.label}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            {showAddModal && <AddDataSource
                source={source}
                showAddModal={showAddModal}
                setShowAddModal={setShowAddModal}
                setConductReload={setConductReload}
            />}

        </>
    )
}

export default DataSourceDropDown
